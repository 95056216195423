@import "variables";

._sidebar_menu {
    display: block;

    ul {
        padding: 0;
        list-style: none;
        margin: 0;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $eventListColor;

        > li {
            &._active > a {
                background: $lightOrange; color: white;

                &:hover { background: $lightOrange}
            }

            > a {
                border-top: 1px solid $subMenuLinkBorder;
                display: block;
                padding: 10px;
                background: $subMenuLinkBg;
                @include animation;
                font-size: 1.2rem;
                line-height: 1.6rem;
                font-weight: 400;
                text-decoration: none;
                color: $subMenuLinkColor;

                &:hover {
                    background: rgba(0,0,0,0.2);
                }
            }

            > h2 {
                border-top: 1px solid $subMenuLinkBorder;
                display: block;
                padding: 10px;
                margin: 0;
                background: rgba(0,0,0,.5);
                @include animation;
                font-size: 1.2rem;
                line-height: 1.6rem;
                font-weight: 400;
                text-decoration: none;
                color: white;
            }

            > ul {

                li {

                    a, h2 {
                        padding: 10px 10px 10px 20px;

                        &:before {
                            content: '-';
                            margin-right: 5px;
                        }
                    }

                    > ul li {
                        a,h2 { padding: 10px 10px 10px 30px;}
                    }
                }
            }
        }
    }
}