@import "variables";

#_static {
    color: $staticParagraphColor;
    font-size: 1.4rem;
    line-height: 2.2rem;

    //Import prawych boksow z badge (tv, galeria)
    @import "box_badge";
    //Import styli dla paginacji
    @import "pagination";
    //Import styli dla sidebar menu
    @import "s_sidebarmenu";

    //Lista inwestycji
    @import "p_investments";
    //Karta inwestycji
    @import "p_investment";
    //Lista aktualnosci
    @import "p_listnews";
    //Pojedyncza aktualnosc
    @import "p_news";
    //Lista filmow
    @import "p_listmovies";
    //Podstrona galeri
    @import "p_galleries";
    //Podstrona kamer
    @import "p_cameras";
    //Podstrona wydarzenia
    @import "p_calendar_event";
    //Podstrona z boxami
    @import "p_box_page";

    //Static styles
    h1 {
        font-size: 3rem;
        color: $staticH1Color;
        font-weight: 700;
        line-height: 3.6rem;
        margin: 0 0 30px;
        padding: 0;
    }

    h2 {
        font-size: 2.4rem;
        color: $staticH2Color;
        font-weight: 700;
        line-height: 3rem;
        margin-top: 20px;
    }

    h3 {
        font-size: 1.8rem;
        color: $staticH3Color;
        font-weight: 700;
        line-height: 3rem;
        margin-top: 20px;
    }

    p {
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: $staticParagraphColor;

        &._lead {
            font-size: 1.6rem;
            line-height: 2.4rem;
        }
    }

    ul {
        color: $staticParagraphColor;
    }

    ._gallery {

        ._item {
            margin-bottom: 30px;
        }
    }

    ._files {

        ._list {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            a {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                padding: 10px 0;
                border-top: $staticFilesBorder;

                &:last-child { border-bottom: $staticFilesBorder;}

                span._title {
                    font-size: 1.4rem;
                    color: $staticFilesTitle;
                    font-weight: 400;
                    line-height: 1.8rem;

                    small {
                        color: $staticFilesFile;
                        font-size: 1.1rem;
                    }
                }

                i {
                    font-size: 1.4rem;
                    color: $staticFilesTitle;
                }
            }
        }
    }

    .movies_zobacz {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

}