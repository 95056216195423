@import "variables";

&._investments {

    #_map {
        background: #f0ede5;
        height: 600px;
        width: 100%;
        display: block;
    }

    #_list {
        display: flex;
        margin-top: 5px;
        flex-direction: column;

        a {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            border-bottom: $investmentsListBorder;
            padding: 5px 0 10px 0;
            text-decoration: none;
            @include animation;

            &:hover {
                background: rgba(0,0,0,0.05);
            }

            span {
                color: $investmentsTitleColor;
                font-size: 1.4rem;
                font-weight: 700;
                line-height: 1.8rem;

                i {
                    font-size: 2.4rem;
                    position: relative;
                    top: 3px;
                }

                &._more {
                    font-size: 1.2rem;
                    font-weight: 400;
                    color: $lightOrange;
                }
            }
        }
    }
}