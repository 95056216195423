@import "variables";

.mega-dropdown {
    position: static !important;
    width: auto;
}

._mainMenu .navbar-default .navbar-nav .mega-dropdown > a:focus,
._mainMenu .navbar-default .navbar-nav .mega-dropdown > a:active {
    background-color: #fff;
}

.bgOverlay {
    position: fixed;
    background-color: transparent;
    background-color: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    display: none;
    z-index: 101;
}

.bgOverlay.isVisible {
    display: block;
    opacity: 1;
}

.mega-dropdown > .row-md-height.row-lg-height {
    width: 100% !important;
    max-width: 100% !important;
    padding: 15px 0;
    display: none;
}

.dropdown.mega-dropdown.open > .row-md-height.row-lg-height {
    display: table;
}


._submenus {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: wrap;

    ._submenu {
        flex-basis: 46%;
        padding: 0 2%;

        ._submenu_title {
            padding: 5px 10px;
            background: $boxTitleColor;
            text-transform: uppercase;
            color: white;
            font-size: 1.4rem;
            line-height: 1.6rem;
            display: inline-block;
            font-weight: 600;
            margin: 0 0 10px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                padding: 5px 10px 5px 15px;
                border-bottom: 1px solid rgba(0,0,0,0.05);

                a {
                    line-height: 1.6rem;
                    color: $navigationLinkColor;
                    font-size: 1.2rem;
                    font-weight: 400;
                }
            }
        }
    }
}
._mainMenu {

    .col-lg-height,
    .col-md-height {
        vertical-align: top;
    }

    ._links_list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {

            a {
                padding: 5px 10px;
                line-height: 2.4rem;
                color: $navigationLinkColor;
                font-size: 1.4rem;
                font-weight: 400;
                text-transform: uppercase;
                display: block;
                cursor: pointer;
            }
        }
    }

    .navbar-default {
        background: transparent !important;
        border: none !important;
        margin-bottom: 0;

        .dropdown.mega-dropdown.open {
            .dropdown-toggle {

                background: $menuLinkBgHover;
                color: $menuLinkColorHover;
                border-color: $menuLinkBorderHover;

                &:after { color: #0e96a5;}
            }
        }

        .dropdown-toggle {
            height: $navigationHeight;
            line-height: $navigationHeight - 4px;
            color: $navigationLinkColor;
            font-size: 1.6rem;
            font-weight: 600;
            text-transform: uppercase;
            border: 2px solid $menuLinkColorBorder;
            background: $menuLinkBg;
            display: block;
            text-decoration: none;
            padding: 0 20px;
            @include animation;
            cursor: pointer;

            i {
                position: relative;
                font-size: 2rem;
            }

            &:not(#_home):hover {
                background: $menuLinkBgHover;
                color: $menuLinkColorHover;
                border-color: $menuLinkBorderHover;
            }
        }
        #_home {
            margin-right: 30px;
            height: $navigationHeight;
            line-height: $navigationHeight - 4px;
            color: $navigationLinkColor;
            font-size: 1.6rem;
            font-weight: 600;
            text-transform: uppercase;
            border: 2px solid $menuLinkColorBorder;
            background: $menuLinkBg;
            display: block;
            text-decoration: none;
            padding: 0;
            @include animation;

            i {
                position: relative;
                font-size: 2rem;
            }

            img {
                position: relative;
                top: -2px;
            }
        }
    }
}