@import "variables";

#_promo_news {

    a._news {
        display: block;
        margin-bottom: 30px;

        figure {
            margin: 0;
            padding: 0;
            position: relative;
            overflow: hidden;

            &:after {
                position: absolute;
                top: 30%;
                left: 0;
                width: 100%;
                height: 207px;
                background: url('../img/slider_shadow.png');
                content: '';
            }

            img {
                width: 100%;
            }

            figcaption {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: 1;

                h2 {
                    margin: 30px 30px 10px;
                    padding: 0;
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                    display: block;
                    color: $mainSliderTitleColor;
                }

                time {
                    margin: 0 0 20px 30px;
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    color: $mainSliderTimeColor;
                    display: block;
                }
            }
        }
    }
}