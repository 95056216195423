@import "variables";

#_breadcrumb {
    padding: 15px 0;
    margin-bottom: 25px;
    display: block;
    border-bottom: $breadcrumbBorder;

    .col-xs-12 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            li {
                color: $breadcrumbTextColor;
                font-size: 1.1rem;
                font-weight: 400;
                text-decoration: none;
                margin-right: 10px;

                a {
                    text-decoration: none !important;

                    &:hover {
                        color: #404040;
                    }
                }

                a:after {
                    content: "\f178";
                    margin-left: 10px;
                    font-family: 'FontAwesome';
                }
            }
        }

        a {
            color: $breadcrumbTextColor;
            text-decoration: none;
            font-size: 1.1rem;
            font-weight: 400;

            &:hover {
                text-decoration: underline;
            }

            i {
                color: #404040;
            }
        }
    }
}