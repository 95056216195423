@import "variables";

#_cameras {
    margin-bottom: 30px;
    display: block;

    a {
        display: block;

        figure {
            margin: 0;
            padding: 20px 0;
            border: $boxCamerasBorder;
            display: flex;
            justify-content: center;
            align-items: center;

            figcaption {
                margin-left: 10px;
                background: $boxCamerasTitleBg;
                color: $boxCamerasTitleColor;
                padding: 7px 20px 5px 20px;
                text-transform: uppercase;
                font-size: 1.8rem;
                line-height: 1.8rem;
                font-weight: 700;
            }
        }
    }
}