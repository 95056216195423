@import "variables";

@media (max-width: $container-lg) {

    header#_header {
        display: none;
    }

    nav#_main {
        display: none;
    }

    #_mobile {
        display: block;

        .navbar-nav {

            > li {

                > a {
                    padding-left: 10px;
                }

                &:first-child {
                    display: none;
                }
            }

            .dropdown-toggle {
                background: #eaeaed;
                border: 2px solid #eaeaed;
                border-bottom: 1px solid #fa0;
                border-top: 1px solid #fa0;
                border-bottom: 1px solid #808080;
                border-top: 1px solid #808080;
            }

            .open .dropdown-toggle {
                background: #fa0;
                background: #808080;
                color: #fff;
                border-color: #fa0;
                border-color: #808080;
            }

        }

        ._submenus ._submenu {
            flex-basis: 100%;
            margin-top: 15px;
        }

        a[data-toggle] {
            position: relative;

            &:after {
                content: "\f078";
                font-family: 'FontAwesome';
                position: absolute;
                top: 0;
                right: 20px;
            }
        }

    }
    ._mainMenu .navbar-default .dropdown.mega-dropdown.open .dropdown-toggle:after {
        color: white;
    }
    ._mainMenu .col-lg-height, ._mainMenu .col-md-height {
        background: white;
    }
    .mega-dropdown>.row-md-height.row-lg-height {
        padding: 0;
    }

    #_recommend .next {
        right: 0;
    }
    #_recommend .prev {
        left: 0;
    }
    .navbar-nav {
        margin: 7.5px 0;
    }

    header#_mobile {
        background: $mHeaderBg;
        padding: 20px 0;
        position: relative;
        z-index: 2;

        #_logo {
            img {
                height: 40px;
            }
        }

        #_icons {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: center;

            #_bip {
                margin-right: 10px;
                img {
                    height: 40px;
                }
            }

            ._search,
            ._menu{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                width: 40px;
                background: $mSearchBg;
                color: white;
                font-size: 2rem;
                text-decoration: none;
            }
            ._menu { background: $mMenuBg;}
        }

        #_mobile_search_box {
            background: $mHeaderBg;
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -100%;
            @include animation;

            form {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 0 15px;

                input[type=text] {
                    height: 40px;
                    line-height: 16px;
                    padding: 10px;
                    background: $searchInputBg;
                    border: 2px solid $searchInputBorder;
                    font-size: 1.2rem;
                    color: $searchInputColor;

                    outline: none !important;
                    width: $searchInputWidth;
                }

                button[type=submit] {
                    width: 40px;
                    height: 40px;
                    font-size: 2rem;
                    color: $searchButtonColor;
                    background: $searchButtonBg;
                    border: 2px solid $searchButtonBorder;
                    outline: none !important;
                }
                #_mobile_search_close{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    width: 40px;
                    background: $mMenuBg;
                    color: white;
                    font-size: 2rem;
                    text-decoration: none;
                }

            } //end header #_top #_details form
        }

        #_mobile_menu_box {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background: $mHeaderBg;

            ul#_menu {
                margin: 0;
                padding: 0;

                li {
                    display: block;
                    border-top: 1px solid $lightOrange;

                    a {
                        display: block;
                        color: black;
                        font-size: 1.8rem;
                        font-weight: 700;
                        line-height: 2.4rem;
                        padding: 10px 15px;
                    }

                    &:first-child,
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }

    #_main_page #_main_slider ._item a figure figcaption h2 {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
    #_main_page #_promo_news a._news figure {
        img,
        &:after {
            display: none;
        }

        figcaption {
            position: relative;

            h2 {
                color: black;
                margin: 0;
                font-size: 1.8rem;
                line-height: 2.2rem;
            }

            time {
                margin: 0;
                padding: 0;
                font-size: 1.2rem;
                color: #404040;
            }
        }
    }

    ._contact {
        text-align: center;
    }
    #_bottom_section ._contact p._phone {
        justify-content: center;
        border-bottom: 1px solid #cecece;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    #_bottom_section ._contact p._phone:before {
        left: 50%;
        margin-left: -15px;
    }

    #_static img {
        max-width: 100% !important;
        width: 100% !important;
        height: auto !important;
    }
    #_bottom_section ._links ul._2_col li {
        flex-basis: 100%;
    }
    #_static._newslist ._list ._news figure a img {
        width: 100px !important;
        max-width: 100px !important;
    }
    #_static._newslist ._list ._news div article time {
        font-size: 1rem;
        line-height: 1.4rem;
    }
    #_static._newslist ._list ._news div article h3 {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    #_static._newslist ._list ._news div {
        height: auto;
    }

    #_calendar_head_bar {
        display: block;
        width: 100%;

        ._navigation {
            justify-content: center;
        }

        ._category_list {
            flex-flow: wrap;
            padding: 10px 10px;
            border-bottom: 1px solid white;

            a {
                margin-bottom: 5px;
                margin-top: 5px;
            }
        }
    }
    #_calendar {

        ._week_days,
        ._week_content {
            width: 100%;

            ._day,
            ._day_content {
                display: block;
                border-top: 2px solid white;
            }
        }
    }

    #_breadcrumb .col-xs-12 a {
        white-space: nowrap;
    }

    .movies_zobacz {

        iframe {
            width: 100% !important;
        }
    }

    #_static ._box_badge ._media._big a {
        height: auto !important;
    }
}