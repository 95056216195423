@import "variables";

._pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    list-style: none;
    margin: 30px 0 0 0;
    padding: 0;

    li {
        margin: 0 5px;

        a, span {
            color: $paginationColor;
            font-size: 1.4rem;
            text-decoration: none;
            font-weight: 700;

            &:hover:not(span) {
                color: $lightOrange;
            }

            &.current { color: $paginationCurrentColor;}

            &.next {
                font-size: 0;

                &:after {
                    content: "\f178";
                    font-family: 'FontAwesome';
                    color: $paginationColor;
                    font-size: 1.4rem;
                }

                &:hover:after { color: $lightOrange;}
            }

            &.prev {
                font-size: 0;

                &:after {
                    content: "\f177";
                    font-family: 'FontAwesome';
                    color: $paginationColor;
                    font-size: 1.4rem;
                }

                &:hover:after { color: $lightOrange;}
            }

            &.disabled {
                cursor: not-allowed;
                &:hover:after {
                    color: $paginationColor;
                }
            }
        }
    }
}