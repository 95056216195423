@import "variables";

footer {
    display: block;
    padding: 30px 0;
    background: $footerBg;

    p {
        margin: 0;
        padding: 0;
        font-size: 1.1rem;
        line-height: 1.6rem;
        color: $footerColor;

        a {
            color: inherit;
            text-decoration: none;
            border-bottom: 1px dotted $footerColor;

            &:hover {
                color: inherit;
            }
        }
    }
}