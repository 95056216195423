@import "variables";

&._galleries {

    @import "box";
    ._box {
        margin-bottom: 30px;
    }

    #_list {

        a._gallery {
            text-decoration: none;
            margin-bottom: 15px;

            &:nth-child(even):after {
                content: '';
                display: block;
                clear: both;
                overflow: hidden;
            }

            figure {
                margin: 0;
                padding: 0;
                position: relative;


                &:before {
                    content: "\f030";
                    font-family: 'FontAwesome';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -20px;
                    margin-left: -20px;
                    font-size: 3rem;
                    color: white;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.5);
                    justify-content: center;
                    align-items: center;
                }

                img {
                    width: 100%;
                    display: block;
                }
            }

            time {
                display: block;
                font-size: 2.4rem;
                line-height: 2.8rem;
                color: $moviesListDateColor;
                margin: 0;
                padding: 0;
                font-weight: 700;
            }

            h2 {
                font-size: 1.8rem;
                line-height: 2.4rem;
                font-weight: 700;
                color: $moviesListTitleColor;
                margin: 0;
                padding: 0;
                height: 50px;
            }
        }
    }
}