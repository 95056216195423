@import "variables";

&._box_page {

    ._boxes {

        ._box {
            margin-bottom: 30px;
            color: $boxPageTitleColor;

            figure {
                margin: 0;
                padding: 0;
                display: block;
                position: relative;
                width: 100%;
                overflow: hidden;
                background: $boxPageBg;

                &:before {
                    content: '';
                    width: 432px;
                    height: 135px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    margin-left: -216px;
                    background: url('../img/box-type-shadow.png');
                }

                img {
                    max-width: 100%;
                }

                figcaption {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    font-size: 2.4rem;
                    line-height: 3rem;
                    color: $boxPageTitleColor;
                    font-weight: 800;
                    padding: 10px;
                }

                &:hover {
                    background: $lightOrange;

                    &:before {
                        background: url('../img/box-type-shadow-hover.png');
                    }

                    img {
                        opacity: .3;
                    }
                }
            }
        }
    }

}