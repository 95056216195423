@import "variables";

#_bottom_section {
    display: block;
    background: $bottomSectionBg;
    padding: 30px 0;

    ._contact {

        img {
            height: 40px;
            margin-bottom: 20px;
        }

        p {
            color: $bottomSectionContactColor;
            font-size: 1.2rem;
            font-weight: 300;
            line-height: 2rem;

            a {
                color: $bottomSectionContactColor;
            }

            &._phone {
                color: $bottomSectionContactPhoneColor;
                font-weight: 700;
                font-size: 1.8rem;
                line-height: 2.4rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-top: 10px;
                margin-top: 10px;
                position: relative;

                &:before {
                    content: '';
                    width: 30px;
                    height: 1px;
                    background: $bottomSectionContactPhoneBorderColor;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                i {
                    color: $bottomSectionContactPhoneIconColor;
                    font-size: 2.4rem;
                    padding-right: 10px;
                }
            }

            &._email {
                color: $bottomSectionContactPhoneColor;
                font-weight: 700;
                font-size: 1.8rem;
                line-height: 2.4rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 10px;
                position: relative;

                i {
                    color: $bottomSectionContactPhoneIconColor;
                    font-size: 2.4rem;
                    padding-right: 10px;
                }

                a {
                    cursor: pointer;
                }
            }
        }
    }

    ._links {
        border-left: 1px solid $bottomSectionLinksBorderColor;

        h2 {
            font-size: 1.4rem;
            line-height: 1.8rem;
            font-weight: 900;
            color: $bottomSectionLinksTitleColor;
            margin: 0 0 25px;
            padding: 0;
            display: block;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            flex-flow: wrap;

            &._2_col li {
                flex-basis: 50%;
            }

            li {
                margin: 5px 0;
                flex-basis: 100%;

                a {
                    color: $bottomSectionLinksLinkColor;
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                }
            }
        }
    }
}