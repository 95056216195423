@import "variables";

#_questionnaire {
    display: block;
    padding: 25px;
    border: $questionnaireBorder;
    margin-top: 10px;
    margin-bottom: 10px;

    h2 {
        margin: 0 0 15px;
        padding: 0;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 700;
        color: $questionnaireTitleColor;
    }

    ul._answers {
        list-style: none;
        margin: 0 0 10px;
        padding: 0;
        display: table;

        li {
            display: table-row;
            margin: 5px 0;
            width: 100%;
            cursor: pointer;

            input[type=radio] {
                margin: 0;
                flex-basis: 20px;

                display: table-cell;
                position: relative;
                top: -1px;
                padding-right: 5px;
                padding-top: 5px;
                padding-bottom: 5px;
            }

            label {
                padding-top: 5px;
                padding-bottom: 5px;
                margin-bottom: 0;
                color: $questionnaireLabelColor;
                font-size: 1.1rem;
                line-height: 1.5rem;
                padding-left: 5px;

                display: table-cell;
                font-weight: 400;
            }
        }
    }

    ._results {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        list-style: none;
        flex-direction: column;
        margin: 0;
        padding: 0;
        width: 100%;

        li {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding-bottom: 5px;
            margin-bottom: 5px;
            border-bottom: 1px solid rgba(0,0,0,0.1);

            span {
                background: red;
                white-space: nowrap;
                background: $lightOrange;
                padding: 5px 15px;
                margin-bottom: 5px;
            }

            font-size: 12px;
            line-height: 18px;
        }
    }

    ._links {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            color: $questionnaireResultColor;
            text-decoration: none;
            font-size: 1.2rem;
        }

        button {
            border: none;
            background: $questionnaireButtonBg;
            color: $questionnaireButtonColor;
            display: inline-block;
            font-size: 1.4rem;
            line-height: 1.8rem;
            padding: 10px 30px;
            outline: none !important;
        }
    }
}