@import "variables";

&._calendar_event {

    #_map {
        background: #f0ede5;
        height: 300px;
        width: 100%;
        display: block;
        margin-top: 20px;
    }

    #_event_spec {
        background: $eventListBg;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border-bottom: 1px solid $eventListBorder;
            padding: 15px;
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: $eventListColor;

            strong, a {
                font-weight: 500;
                text-decoration: none;
                color: $eventListColorBold;
            }
        }
    }
}