@import "variables";

._box {
    display: block;

    h2 {
        padding: 5px 10px;
        background: $boxTitleColor;
        text-transform: uppercase;
        color: white;
        font-size: 1.4rem;
        line-height: 1.6rem;
        display: inline-block;
        font-weight: 600;
        margin: 0 0 10px;
    }

    ul {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;

            &:last-child a { border-color: transparent;}

            a {
                display: block;
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: $boxLinkColor;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-decoration: none;
                padding: 5px 0 5px 2px;
                margin: 0;
                border-bottom: 1px solid $boxLinkBorder;
            }
        }
    }
}