@import "variables";

&._newslist {

    ._list {
        display: flex;
        flex-direction: column;
        width: 100%;

        ._news {
            display: table;
            width: 100%;

            &:nth-child(even) {
                figure, div {
                    border-color: $newsListBorderEvenColor;
                    background: $newsListBackgroundEvenColor;
                }
            }

            figure {
                display: table-cell;
                margin: 0;
                padding: 0;
                width: 260px;

                border-top: 10px solid $newsListBorderOddColor;
                border-left: 15px solid $newsListBorderOddColor;
                border-bottom: 10px solid $newsListBorderOddColor;

                a {
                    display: block;

                    img {
                        width: 260px;
                    }
                }
            }

            div {
                width: 100%;
                padding-left: 30px;
                display: table-cell;
                height: 100%;
                vertical-align: top;

                border-top: 10px solid $newsListBorderOddColor;
                border-right: 15px solid $newsListBorderOddColor;
                border-bottom: 10px solid $newsListBorderOddColor;

                article {
                    margin: 0;
                    //height: 100%;
                    overflow-y: auto;

                    //display: flex;
                    //align-items: flex-start;
                    //flex-direction: column;
                    padding: 0;

                    time, h3, p, a {
                        margin: 0;
                        padding: 0;
                    }

                    time {
                        color: $newsListTimeColor;
                        font-size: 2.4rem;
                        font-weight: 700;
                        line-height: 2.8rem;
                    }

                    h3 {
                        font-size: 1.8rem;
                        line-height: 2.2rem;
                        font-weight: 700;
                        color: $newsletterTitleColor;
                        margin: 10px 0;
                    }

                    p {
                        font-size: 1.4rem;
                        line-height: 2rem;
                        font-weight: 400;
                        color: $newsListParagraphColor;
                    }

                     a {
                         font-size: 1.2rem;
                         color: $newsListLinkColor;
                     }
                }
            }
        }
    }
}