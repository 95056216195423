@mixin animation {
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.mt30 { margin-top: 30px;}

$lightOrange:               #ffaa00 !default;
$lightYellow:               #fadd3b !default;


//RESOLUTIONS
$container-sm: 767px;
$container-md: 992px;
$container-lg: 1170px;

/***
 *
 * Header variables
 *
*/
$headerBg:                  #eaeaed !default;

    $searchInputBg:             white !default;
    $searchInputBorder:         white !default;
    $searchInputColor:          black !default;
    $searchInputWidth:          300px !default;
    $searchButtonBg:            $lightOrange !default;
    $searchButtonColor:         white !default;
    $searchButtonBorder:        $lightOrange !default;

    $fontSizeColor:             #666666 !default;
    $contrastColor:             #666666 !default;

    $weatherColor:              #005185 !default;

    $dayInfoColor:              #404040 !default;
    $dayInfoNameColor:          #808080 !default;
/***
 *
 * Navigation variables
 *
*/
$navigationBg:                  white !default;
    $navigationHeight:          60px !default;
    $navigationLinkColor:       #262626 !default;
    $menuLinkColor:             white !default;
    $menuLinkColorBorder:       white !default;
    $menuLinkBg:                white !default;
    $menuLinkBgHover:           $lightOrange !default;
    $menuLinkColorHover:        white !default;
    $menuLinkBorderHover:       $lightOrange !default;
/***
 *
 * Main slider variables
 *
*/
$mainSliderTitleColor:          white;
$mainSliderTimeColor:           $lightYellow;
$mainSliderArrowsColor:         white !default;
$mainSliderDotsBg:              $lightOrange !default;
$mainSliderDotsActiveBg:        white !default;
/***
 *
 * Box variables
 *
*/
$boxTitleColor:                 #005185 !default;
$boxLinkColor:                  #404040 !default;
$boxLinkBorder:                 #eaeaed !default;
    $boxCamerasBorder:          5px solid #005185 !default;
    $boxCamerasTitleBg:         #005185 !default;
    $boxCamerasTitleColor:      white !default;
$boxBadgeTitleColor:            black !default;
$boxBadgeBg:                    $lightOrange !default;
$boxBadgeColor:                 white !default;
/***
 *
 * Newsletter variables
 *
*/
$newsletterBg:                  #eaeaed !default;
$newsletterTitleColor:          black !default;
$newsletterInputMailColor:      #999999 !default;
$newsletterButtonColor:         white !default;
$newsletterButtonBg:            $lightOrange !default;
/***
 *
 * Questionnaire variables
 *
*/
$questionnaireBorder:           2px solid #ffd47f !default;
$questionnaireTitleColor:       black !default;
$questionnaireLabelColor:       #666666 !default;
$questionnaireResultColor:      $lightOrange !default;
$questionnaireButtonColor:      white !default;
$questionnaireButtonBg:         $lightOrange !default;
/***
 *
 * Event Calendar variables
 *
*/
$eventCalendarHeadBg:                   #333333 !default;
$eventCalendarHeadColor:                white !default;
$eventCalendarHeadBorderColor:          #1a1a1a !default;
$eventCalendarHeadFullMonthBg:          #1a1a1a !default;
$eventCalendarHeadFullMonthColor:       white !default;
$eventCalendarDetailsBg:                #4d4d4d !default;
$eventCalendarDetailsLinkColor:         white !default;
$eventCalendarDetailsCategoryColor:     #b2b2b2 !default;
$eventCalendarDetailsTitleColor:        white !default;
/***
 *
 * Close events variables
 *
*/
$closeEventsTitleColor:         #005185 !default;
$closeEventsPlaceColor:         #666666 !default;
/***
 *
 * Recommend slider variables
 *
*/
$recommendTopBorderColor:       #e6e6e6 !default;
$recommendArrowsColor:          #808080 !default;
/***
 *
 * Bottom section variables
 *
*/
$bottomSectionBg:                       #f2f2f2 !default;
$bottomSectionContactColor:             #404040 !default;
$bottomSectionContactPhoneColor:        #1a1a1a !default;
$bottomSectionContactPhoneIconColor:    #005185 !default;
$bottomSectionContactPhoneBorderColor:  #cecece !default;
$bottomSectionLinksBorderColor:         #d9d9d9 !default;
$bottomSectionLinksTitleColor:          #404040 !default;
$bottomSectionLinksLinkColor:           #737373 !default;
/***
 *
 * Footer variables
 *
*/
$footerBg:      #1a1a1a !default;
$footerColor:   #808080 !default;
/***
 *
 * Breadcrumb variables
 *
*/
$breadcrumbBorder:          1px solid #e5e5e5 !default;
$breadcrumbTextColor:       #808080 !default;
/***
 *
 * Static variables
 *
*/
$staticH1Color:             black !default;
$staticH2Color:             #404040 !default;
$staticH3Color:             #404040 !default;
$staticParagraphColor:      #404040 !default;
$staticFilesBorder:         1px solid #e5e5e5 !default;
$staticFilesTitle:          #404041 !default;
$staticFilesFile:           #999999 !default;
/***
 *
 * Investments variables
 *
*/
$investmentsListBorder:     1px solid #e5e5e5 !default;
$investmentsTitleColor:     #404040 !default;
/***
 *
 * Calendar variables
 *
*/
$calendarBg:                        #4d4d4d !default;
$calendarWeekDayBg:                 #333333 !default;
$calendarWeekDayBorder:             #000000 !default;
$calendarWeekContentBorder:         #333333 !default;
$calendarWeekendContentBg:          #333333 !default;
$calendarWeekendTitleBg:            #000000 !default;
$calendarWeekendTitleBorder:        #333333 !default;
$calendarWeekendContentBorder:      #000 !default;
$calendarEventNameColor:            #fff !default;
$calendarEventCategoryColor:        #b2b2b2 !default;
$calendarNaviColor:                 white !default;
$calendarNaviBorder:                #333333 !default;
/***
 *
 * News list variables
 *
*/
$newsListBorderEvenColor:           #f2f2f2 !default;
$newsListBorderOddColor:            white !default;
$newsListBackgroundEvenColor:       #f2f2f2 !default;
$newsListTimeColor:                 #bfbfbf !default;
$newsListTitleColor:                #000000 !default;
$newsListParagraphColor:            #404040 !default;
$newsListLinkColor:                 $lightOrange !default;
/***
 *
 * Pagination variables
 *
*/
$paginationColor:               #404040 !default;
$paginationCurrentColor:        #999999 !default;
/***
 *
 * Movies list variables
 *
*/
$moviesListDateColor:           #bfbfbf !default;
$moviesListTitleColor:          #000000 !default;
/***
 *
 * Calendar event variables
 *
*/
$eventListBg:          #4d4d4d !default;
$eventListBorder:      #333333 !default;
$eventListColor:       #cccccc !default;
$eventListColorBold:   white !default;
/***
 *
 * Submenu sidebar variables
 *
*/
$subMenuTitleColor:         #404040 !default;
$subMenuTitleBorder:        #e5e5e5 !default;
$subMenuLinkBorder:         #d5d5d5 !default;
$subMenuLinkColor:          #595959 !default;
$subMenuLinkBg:             #ededed !default;
/***
 *
 * Box page variables
 *
*/
$boxPageBg:                 #595959 !default;
$boxPageTitleColor:         white !default;
/***
 *
 * Mobile variables
 *
*/
$mHeaderBg:             #eaeaed !default;
$mSearchBg:             $lightOrange !default;
$mMenuBg:               #005185 !default;