@import "variables";

._box_badge {
    display: block;
    margin-bottom: 37px;
    position: relative;

    h2 {
        margin: 0 0 15px 0;
        padding: 0;
        display: block;
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: $boxBadgeTitleColor;
        font-weight: 700;

        &:after {
            content: attr(data-badge);
            padding: 5px 10px;
            color: $boxBadgeColor;
            background: $boxBadgeBg;
            margin-left: 10px;
        }
    }

    ._more {
        display: block;
        text-align: right;
        margin-top: 5px;
        color: $lightOrange;
        font-weight: 700;
        font-size: 1rem;
        position: absolute;
        top: 0;
        right: 0;
    }

    ._media {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: wrap;

        &#_movies a figure {

            &:before {
                content: "\f04b";
                font-family: 'FontAwesome';
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -20px;
                margin-left: -20px;
                font-size: 1.5rem;
                color: white;
                border: 5px solid white;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: flex;
                box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.5);
                justify-content: center;
                align-items: center;
            }
        }

        a {
            display: block;
            max-width: 165px;
            color: white;

            &:not(:nth-of-type(1)):not(:nth-of-type(2)) {
                margin-top: 30px;
            }

            figure {
                margin: 0;
                padding: 0;
                position: relative;
                display: block;

                &:after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 45px;
                    content: '';
                    background: url('../img/movie_shadow.png') repeat-x;
                }

                figcaption {
                    position: absolute;
                    bottom: 5px;
                    left: 5px;
                    z-index: 1;
                    font-size: 1.1rem;
                    line-height: 1.6rem;
                }
            }
        }

        &._big {
            //display: flex;
            width: 100%;
            flex-direction: column;

            a {
                width: 100%;
                max-width: 100%;
                flex-basis: 100%;
                margin-bottom: 10px !important;
                margin-top: 0 !important;
                height: 215px
            }



            &#_photos a figure {

                &:before {
                    content: "\f030";
                    font-family: 'FontAwesome';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -20px;
                    margin-left: -20px;
                    font-size: 3rem;
                    color: white;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.5);
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}