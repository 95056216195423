@import "variables";

nav#_main {
    display: block;
    background: $navigationBg;
    box-shadow: 0 2px 5px 0 rgba(152,151,163,0.3);
    position: relative;

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    #_menu {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        a {
            cursor: pointer;
        }

        > li {
            display: block;
            margin: 0;
            padding: 0;

            > a {
                height: $navigationHeight;
                line-height: $navigationHeight - 4px;
                color: $navigationLinkColor;
                font-size: 1.6rem;
                font-weight: 600;
                text-transform: uppercase;
                border: 2px solid $menuLinkColorBorder;
                background: $menuLinkBg;
                display: block;
                text-decoration: none;
                padding: 0 20px;
                @include animation;

                i {
                    position: relative;
                    font-size: 2rem;
                }

                &:not(#_home):hover {
                    background: $menuLinkBgHover;
                    color: $menuLinkColorHover;
                    border-color: $menuLinkBorderHover;
                }

                &#_home {
                    padding: 0;
                    margin-right: 30px;

                    img {
                        position: relative;
                        top: -2px;
                    }
                }
            }

            &:hover {

                > a:not(#_home) {
                    background: $menuLinkBgHover;
                    color: $menuLinkColorHover;
                    border-color: $menuLinkBorderHover;

                    &:after { color: #0e96a5;}
                }
                /*

                    .submenu {
                        display: flex;
                    }
                */
            }
        }
    }


    .submenu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;

        background: #fff;
        margin: 0;
        padding: 15px;
        z-index: 100;
        box-sizing: border-box;
        box-shadow: inset 0 0 0 2px $lightOrange,2px 2px 4px 0 rgba(0,0,0,0.2);


        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: wrap;

        ._menu {
            font-size: 12px;
            flex-basis: 25%;

            >a {
                color: $lightOrange;
                height: 30px;
                line-height: 20px;
                font-size: 14px;
                font-weight: normal;
                display: block;
            }

            >ul {
                border-left: 1px solid #ccc;
                list-style: none;
                margin: 0 0 10px;
                padding: 0;

                > li {
                    margin: 0;
                    padding: 0;
                }

                a {
                    display: block;
                    height: 20px;
                    color: #000;
                    font-size: 1.2rem;
                    line-height: 1.8rem;

                    &:before {
                        content: '';
                        height: 1px;
                        width: 5px;
                        position: absolute;
                        top: 7px;
                        left: 0;
                        display: inline-block;
                        vertical-align: middle;
                        background: #ccc;
                    }
                }
            }
        }

        .column {
            flex-basis: 25%;
            display: inline-block;
            font-size: 12px;

            >a {
                color: $lightOrange;
                margin: 5px 10px 0 0;
                line-height: 22px;
                font-size: 14px;
                font-weight: normal;
                display: block;
            }

            >ul {
                border-left: 1px solid #ccc;
                list-style: none;
                margin: 0 0 10px;
                padding: 0;

                > li {
                    margin: 0;
                    padding: 0;
                    position: relative;
                }

                a {
                    display: block;
                    color: #000;
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    padding-left: 10px;

                    &:before {
                        content: '';
                        height: 1px;
                        width: 5px;
                        position: absolute;
                        top: 7px;
                        left: 0;
                        display: inline-block;
                        vertical-align: middle;
                        background: #ccc;
                    }
                }
            }
        }
    }

    #_socials {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: $navigationHeight;

        a {
            margin-left: 5px;
        }
    }
}


#menuShadow {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.2);
    z-index: 1;
}