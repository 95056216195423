@import "variables";


#_banners_small {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: wrap;

    a {
        display: block;
        margin-bottom: 10px;
    }
}