@import "variables";

#_main_slider {
    margin-bottom: 0;
    position: relative;

    .prev,
    .next {
        position: absolute;
        left: 30px;
        width: 26px;
        height: 36px;
        line-height: 4rem;
        text-align: center;
        font-size: 3.6rem;
        top: 50%;
        margin-top: -18px;
        cursor: pointer;
        color: $mainSliderArrowsColor;
        z-index: 1;
        opacity: .5;
        @include animation;

        &:hover { opacity: 1;}

        i {
            position: relative;
        }
    }
    .next {
        left: auto;
        right: 30px;
    }

    ._item {

        a {
            display: block;


            figure {
                margin: 0;
                padding: 0;
                position: relative;
                overflow: hidden;

                &:after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 207px;
                    background: url('../img/slider_shadow.png');
                    content: '';
                }

                img {
                    width: 100%;
                }

                figcaption {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    z-index: 1;

                    h2 {
                        margin: 30px 30px 20px;
                        padding: 0;
                        font-size: 3.6rem;
                        line-height: 4.2rem;
                        display: block;
                        color: $mainSliderTitleColor;
                    }

                    time {
                        margin: 0 0 20px 30px;
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                        color: $mainSliderTimeColor;
                        display: block;
                    }
                }
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 20px;
        right: 30px;
        list-style: none;
        display: block;
        text-align: right;
        padding: 0;
        margin: 0;
        width: 100%;

        li {
            position: relative;
            display: inline-block;
            height: 10px;
            width: 10px;
            margin: 0 0 0 5px;
            list-style: none;
            padding: 0;
            cursor: pointer;
            background: $mainSliderDotsBg;
            @include animation;

            button {
                display: none !important;

                &:hover, &:focus {
                    outline: none;
                    &:before {
                    }
                }
            }

            &.slick-active {
                background: $mainSliderDotsActiveBg;
            }
        }
    }
}