@import "variables";

&._cameras {

    .fancyiframe {
        display: block;
        position: relative;

        &:before {
            content: "\f144";
            font-family: 'FontAwesome';
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -20px;
            margin-left: -20px;
            font-size: 5rem;
            color: white;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.5);
            justify-content: center;
            align-items: center;
        }
    }
}