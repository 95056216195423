._contrast {
    font-size: 11px;

    body {
        background: black;
    }

    header#_header,
    #_bottom_section,
    footer {
        background: black;
    }

    nav#_main {
        background: black;
        border-top: 2px solid yellow;
        border-bottom: 2px solid yellow;
    }

    nav#_main #_menu>li>a {
        background: black;
        border: 2px solid black;
        text-decoration: underline;
        color: yellow;
        font-size: 1.5rem;
    }
    nav#_main #_menu>li:hover>a:not(#_home), nav#_main #_menu>li>a:not(#_home):hover {
        background: yellow;
        color: black;
        border-color: yellow;
    }

    nav#_main .submenu {
        box-shadow: inset 0 0 0 2px yellow,2px 2px 4px 0 rgba(0,0,0,.2);
        background: black;
    }
    nav#_main .submenu .column>a,
    nav#_main .submenu .column>ul a {
        color: yellow;
        text-decoration: underline;
    }
    header#_header #_top #_details #_contrast_version {
        color: white;
    }
    header#_header #_top #_details #_weatherm,
    header#_header #_top #_details #_bip,
    nav#_main #_menu>li>a#_home img,
    header#_header #_top #_details #_weather,
    header#_header #_top #_details #_day_info,
    #_logo {
        filter: grayscale(100%) brightness(10000%);
    }
    header#_header #_top #_details form button[type=submit] {
        background: yellow;
        border: 2px solid yellow;
        color: black;
        margin-left: 5px;
    }
    header#_header #_top #_details form input[type=text] {
        font-size: 1.8rem;
    }
    #_main_page #_main_slider {
        border: 2px solid yellow;
    }
    #_main_page #_main_slider ._item a figure:after {
        background: black;
        height: 150px;
    }
    #_main_page #_main_slider .next, #_main_page #_main_slider .prev {
        color: yellow;
        text-shadow: 1px 2px 1px black;
        opacity: 1;
    }
    #_main_page #_promo_news a._news {
        border: 2px solid yellow;

        figure:after {
            background: black;
            top: auto;
            bottom: 0;
            height: 100px;
        }
    }

    #_main_page ._box h2 {
        background: yellow;
        color: black;
    }
    #_main_page ._box ul li a {
        color: yellow;
        border-color: white;
    }
    #_main_page #_close_events ._event figure figcaption h3 {
        color: yellow;
    }
    #_main_page #_close_events ._event figure figcaption h4, #_main_page #_close_events ._event figure figcaption time {
        color: white;
    }
    #_recommend {
        background: white;
    }
    #_recommend .next, #_recommend .prev {
        color: black;
        opacity: 1;
    }
    #_main_page #_events_calendar ._head ._left, #_main_page #_events_calendar ._head ._right {
        background: yellow;
        color: black;
    }
    #_main_page #_events_calendar ._head ._left h3, #_main_page #_events_calendar ._head ._right h3,
    #_main_page #_events_calendar ._head ._left h4, #_main_page #_events_calendar ._head ._right h4 {
        color: black;
        font-weight: 600;
    }
    #_main_page #_events_calendar ._head a._full_month {
        border: 2px solid yellow;
        background: black;
    }
    #_main_page #_events_calendar ._details {
        background: black;
        border: 2px solid yellow;
    }
    #_main_page #_events_calendar ._details:after {
        width: 2px;
        background: yellow;
        margin-left: -1px;
    }
    #_main_page #_events_calendar ._head:after {
        width: 2px;
        top: 2px;
        background: black;
        margin-left: -1px;
    }
    #_main_page #_events_calendar {
        border-bottom: 0;
    }
    #_main_page ._box_badge h2 {
        color: yellow;
    }
    #_main_page ._box_badge h2:after {
        background: yellow;
        color: black;
    }
    #_main_page ._box_badge ._more,
    #_static ._box_badge ._more {
        color: yellow;
        text-decoration: underline;
        font-weight: 400;
        font-size: 1.4rem;
    }
    #_main_page #_questionnaire h2 {
        color: yellow;
    }
    #_main_page #_questionnaire {
        border-color: yellow;
    }
    #_main_page #_questionnaire ul li label {
        color: white;
        font-size: 1.2rem;
    }
    #_main_page #_questionnaire ._links a {
        font-size: 1.4rem;
        color: yellow;
        text-decoration: underline;
    }
    #_main_page #_questionnaire ._links button,
    #_main_page #_newsletter form button[type=submit] {
        background: yellow;
        color: black;
    }
    #_main_page #_newsletter {
        border: 2px solid yellow;
        background: black;
    }
    #_main_page #_newsletter:before {
        display: none;
    }
    #_main_page #_newsletter h2 {
        color: yellow;
    }
    #_main_page #_newsletter form input[type=email] {
        color: black;
        font-size: 1.4rem;
    }
    #_main_page #_cameras a figure {
        border-color: yellow;
    }
    #_main_page #_cameras a figure img {
        display: none;
    }
    #_main_page #_cameras a figure figcaption {
        background: yellow;
        color: black;
    }
    #_bottom_section ._contact p a,
    #_bottom_section ._contact p,
    #_bottom_section ._contact p._phone,
    footer p {
        color: white;
    }
    #_bottom_section ._contact p._phone i {
        color: yellow;
    }
    #_bottom_section ._links h2,
    #_bottom_section ._links ul li a {
        color: yellow;
    }
    #_bottom_section ._links {
        border-left: 5px solid white;
    }
    #_main_page ._box_badge ._media a figure figcaption,
    #_static ._box_badge ._media a figure figcaption {
        position: static;
        margin-top: 2px;
        color: yellow;
    }
    #_main_page ._box_badge ._media#_movies a figure:before,
    #_static ._box_badge ._media#_movies a figure:before,
    #_static ._box_badge ._media a figure:after,
    #_static ._box_badge ._media._big#_photos a figure:before {
        display: none;
    }
    #_static h1 {
        color: yellow;
    }
    #_static p {
        color: white;
    }

    table {
        color: black !important;
    }
    #_static ._box_badge h2 {
        color: yellow;
    }
    #_static ._box_badge h2:after {
        background: yellow;
        color: black;
    }
    #_breadcrumb .col-xs-12 a,
    #_breadcrumb .col-xs-12 a i {
        color: yellow;
    }
    #_breadcrumb .col-xs-12 ul li {
        color: white;
    }
    #_breadcrumb {
        border-bottom: 2px solid white;
    }
    #_static._investments #_list a span,
    #_static._investment #_list li a,
    #_static._investment #_list li a span,
    #_static._investment #_list li a i {
        color: yellow;
    }
    #_static ul,
    #_static {
        color: white;
    }

    #_static._box_page ._boxes ._box figure:before {
        background: black;
    }
    #_static._box_page ._boxes ._box figure {
        border: 2px solid yellow;



        &:hover {
            background: black;

            &:before {
                background: black;
            }

            img {
                opacity: 1;
            }
        }
    }

    #_static._calendar_event #_event_spec {
        background: black;
        border: 2px solid yellow;
    }
    #_static._calendar_event #_event_spec li {
        color: white;
    }
    #_static._calendar_event #_event_spec li a, #_static._calendar_event #_event_spec li strong {
        color: yellow;
    }
    #_static._calendar_event #_event_spec li:not(:last-child) {
        border-bottom: 2px solid yellow;
    }
    #_static h2 {
        color: yellow;
    }
    #_main_page ._box_badge ._media a figure:after {
        display: none;
    }

    #_calendar_head_bar {
        background: black;
        border: 2px solid yellow;
    }
    #_calendar_head_bar ._navigation span._name_month {
        border: none;
    }
    #_calendar_head_bar ._navigation a {
        background: yellow;
        color: black;
    }
    #_calendar ._week_content ._day, #_calendar ._week_days ._day {
        border: 1px solid yellow;
        background: black;
    }
    #_calendar ._week_content ._day_content, #_calendar ._week_days ._day_content {
        background: black;
        border: 1px solid yellow;
    }
    #_calendar ._event {
        border-bottom: 2px solid white;
    }
    #_calendar ._event ._name {
        font-size: 1.3rem;
    }
    #_calendar ._event ._category {
        color: white;
        font-size: 1.1rem;
    }
    #_calendar ._more {
        color: yellow;
        text-decoration: underline;
    }
    #_static._cameras .fancyiframe:before {
        color: yellow;
    }
    #_static._newslist ._list ._news figure,
    #_static._newslist ._list ._news div {
        border-color: black;
    }
    ._contrast #_static._newslist ._list ._news div, ._contrast #_static._newslist ._list ._news figure,
    #_static._newslist ._list ._news:nth-child(even) div, #_static._newslist ._list ._news:nth-child(even) figure {
        background: black;
    }
    #_static._newslist ._list ._news {
        border: 2px solid yellow;
        margin-bottom: 5px;
    }
    #_static._newslist ._list ._news div article time {
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: white;
    }
    #_static._newslist ._list ._news div article h3 {
        color: yellow;
    }
    #_static._newslist ._list ._news div article p {
        color: white;
    }
    #_static._newslist ._list ._news div article a {
        color: yellow;
        text-decoration: underline;
    }
    #_static ._pagination li a.current, #_static ._pagination li span.current {
        color: black;
        background: yellow;
        padding: 0 5px;
    }
    #_static ._pagination li a, #_static ._pagination li span {
        color: yellow;
    }
    #_static ._pagination li a.next:after, #_static ._pagination li span.next:after,
    #_static ._pagination li a.prev:after, #_static ._pagination li span.prev:after {
        color: yellow;
    }

    #_static ._sidebar_menu > ul {
        border: 2px solid yellow;
    }
    #_static ._sidebar_menu ul>li._active>a {
        background: yellow;
        color: black;
    }
    #_static ._sidebar_menu ul>li>a {
        background: black;
        color: white;
        border-bottom: 2px solid white;
    }

    #_static h3 {
        color: yellow;
    }
    .table {
        color: white !important;
    }
    .table-striped>tbody>tr:nth-of-type(odd) {
        background: black;
    }

    .btn {
        background: yellow !important;
        color: black !important;
    }

    #_calendar_day ._event {
        border: 2px solid yellow;
        margin: 10px 0;
        background: none !important;
    }
    #_calendar_day ._event ._details ._title {
        color: yellow;
    }
    #_calendar_day ._event ._details ._category {
        color: yellow;
    }
    ._mainMenu .navbar-default .dropdown-toggle {
        background: transparent;
        color: yellow;
        border-color: black;
        padding: 0 17px;
    }
    ._mainMenu .navbar-default .dropdown-toggle:not(#_home):hover,
    ._mainMenu .navbar-default .dropdown.mega-dropdown.open .dropdown-toggle {
        background: yellow;
        color: black;
        border-color: yellow;
    }
    ._mainMenu .navbar-default #_home {
        background: transparent;
        color: yellow;
        border-color: black;
        filter: grayscale(100%) brightness(10000%);
    }
    .dropdown-menu {
        background: black;
        border: 2px solid yellow;
    }
    ._mainMenu ._links_list li a,
    ._submenus ._submenu ul li a,
    #_static._galleries ._box ul li a {
        color: yellow;
        text-decoration: underline;
        border-bottom: none;
    }
    ._submenus ._submenu ._submenu_title,
    #_static._galleries ._box h2{
        background: yellow;
        color: black;
    }
    #_bottom_section ._contact p._email i {
        color: yellow;
    }

}