@import "variables";

#_banners {
    margin-top: 30px;
    display: block;

    a {
        display: block;
        margin-bottom: 10px;
        margin-bottom: 10px;
    }
}