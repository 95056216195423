@import "variables";

#_close_events {
    margin-top: 30px;
    display: block;

    ._event {
        margin-bottom: 15px;
        text-decoration: none;
        color: $closeEventsTitleColor;

        figure {
            margin: 0;
            padding: 0;

            img {
                max-width: 100%;
            }

            figcaption {
                display: block;

                h3 {
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    color: $closeEventsTitleColor;
                    margin: 5px 0 0 0;
                    font-weight: 400;
                    padding: 0;
                }

                h4, time {
                    font-size: 1.1rem;
                    line-height: 1.5rem;
                    color: $closeEventsPlaceColor;
                    margin: 0;
                    font-weight: 400;
                    padding: 0;
                }
            }
        }
    }
}