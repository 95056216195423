@import "variables";

header#_header {
    background: $headerBg;
    display: block;
    padding: 15px 0 10px;

    #_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        #_details {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            form,
            #_bip,
            #_font_size,
            #_contrast_version,
            #_weather {
                padding-right: 15px;
                margin-right: 15px;
                position: relative;

                &:after {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -10px;
                    height: 20px;
                    width: 1px;
                    background: #cbcbd1;
                    content: '';
                }
            }

            form {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                input[type=text] {
                    height: 40px;
                    line-height: 16px;
                    padding: 10px;
                    background: $searchInputBg;
                    border: 2px solid $searchInputBorder;
                    font-size: 1.2rem;
                    color: $searchInputColor;

                    outline: none !important;
                    width: $searchInputWidth;
                }

                button[type=submit] {
                    width: 40px;
                    height: 40px;
                    font-size: 2rem;
                    color: $searchButtonColor;
                    background: $searchButtonBg;
                    border: 2px solid $searchButtonBorder;
                    outline: none !important;
                }
            } //end header #_top #_details form

            #_bip {
                padding-top: 7px;

            } //end header #_top #_details #_bip

            #_font_size {
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    color: $fontSizeColor;
                    font-weight: 400;
                    cursor: pointer;

                    &._active { color: $lightOrange;}

                    &#_small {
                        font-size: 1.1rem;
                    }

                    &#_normal {
                        font-size: 1.6rem;
                        margin: 0 8px;
                    }

                    &#_big {
                        font-size: 2.4rem;
                    }
                }

            } //end header #_top #_details #_font_size

            #_contrast_version {

                font-size: 1.1rem;
                line-height: 1.1rem;
                color: $contrastColor;
                text-decoration: none;
                padding-left: 25px;

                &:before {
                    width: 20px;
                    height: 20px;
                    background: url('../img/wersja_kontrastowa.png');
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -10px;
                }

            } //end header #_top #_details #_contrast_version

            #_weather {
                margin: 0 15px 0 0;
                padding: 0 15px 0 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                i {
                    font-size: 3rem;
                    line-height: 4rem;
                }

                figcaption {
                    color: $weatherColor;
                    font-size: 1.8rem;
                    font-weight: 600;
                    margin-left: 10px;
                }

            } //end header #_top #_details #_weather

            #_day_info {
                font-size: 1.05rem;
                line-height: 1.1rem;
                color: $dayInfoColor;
                max-width: 125px;

                span { display: block;}
                #_day { white-space: nowrap}

                #_name { color: $dayInfoNameColor;}

            } //end header #_top #_details #_day_info

        } //end header #_top #_details
    } //end header #_top

    #_advert {
        a {
            display: block;
        }
    }
} //end header

