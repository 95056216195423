@import "variables";

#_newsletter {
    padding: 20px 15px;
    overflow: hidden;
    position: relative;
    background: $newsletterBg;
    display: block;

    &:before {
        content: "\f0e0";
        position: absolute;
        top: -15px;
        right: -5px;
        font-family: 'FontAwesome';
        color: white;
        font-size: 6rem;
        transform: rotate(45deg);
    }

    h2 {
        margin: 0;
        padding: 0;
        font-size: 2.4rem;
        line-height: 2.8rem;
        color: $newsletterTitleColor;
        font-weight: 600;
    }

    form {
        margin-top: 20px;
        display: flex;
        flex-flow: wrap;
        width: 100%;

        input[type=email] {
            width: 70%;
            padding: 10px;
            line-height: 2rem;
            color: $newsletterInputMailColor;
            font-size: 1.2rem;
            font-weight: 400;
            border: none;
            outline: none !important;
        }

        button[type=submit] {
            width: 30%;
            white-space: nowrap;
            padding: 10px 20px;
            line-height: 2rem;
            font-size: 1.4rem;
            font-weight: 700;
            color: $newsletterButtonColor;
            background: $newsletterButtonBg;
            border: none;
            outline: none !important;
        }

        .messages {
            text-align: center;
            padding: 5px 10px;
            flex-basis: 100%;

            &.error { background:#f2dede; color: #a94442;}
            &.success { background: #dff0d8; color: #3c763d;}
        }
    }
}