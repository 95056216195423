html,body { display: block; width: 100%;}
body { font-size: 10px; font-family: 'Roboto', sans-serif;}

@import "partials/bootstrap_same_height";

@import "partials/header";
@import "partials/navigation";
@import "partials/new_navigation";

@import "partials/main";
@import "partials/breadcrumb";
@import "partials/static";
@import "partials/p_error_page";

@import "partials/calendar";
@import "partials/slider_gallery";

@import "partials/recommend";
@import "partials/bottom_section";
@import "partials/footer";

._contrast_fade {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    background: white;
    z-index: 99999;
}

#_mobile {
    display: none;
}

@import "partials/max-767";
@import "partials/contrast_version";