@import "variables";

#_gallery_slider {

    * {
        outline: none !important;
    }

    ._gallery_slider_big {
        margin-bottom: 0;

        ._item {

            a {
                display: block;

                img {
                    max-width: 100%;
                }
            }
        }
    }

    ._gallery_slider_small {

        ._item {
            margin: 3px;
            border: 2px solid white;
            @include animation;

            &.slick-current {
                border-color: $lightOrange;
            }

            img {
                outline: none !important;
                width: 100%;
                cursor: pointer;
            }
        }

        .prev,
        .next {
            position: absolute;
            left: -30px;
            width: 26px;
            height: 36px;
            line-height: 4rem;
            text-align: center;
            font-size: 3.6rem;
            top: 50%;
            margin-top: -18px;
            cursor: pointer;
            color: $lightOrange;
            z-index: 1;
            opacity: .5;
            @include animation;

            &:hover { opacity: 1;}

            i {
                position: relative;
            }
        }
        .next {
            left: auto;
            right: -30px;
        }
    }
}