@import "variables";

&._investment {

    #_list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: block;

        li {
            display: block;

            a {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                align-items: center;
                border-bottom: $investmentsListBorder;
                padding: 10px 5px;
                text-decoration: none;
                @include animation;
                color: $investmentsTitleColor;

                &:hover {
                    background: rgba(0,0,0,0.05);
                }

                i {
                    font-size: 2.4rem;
                    position: relative;
                    margin-right: 5px;
                    color: $investmentsTitleColor;
                }

                span {
                    color: $investmentsTitleColor;
                    font-size: 1.2rem;
                    font-weight: 700;
                    line-height: 1.6rem;

                    &._more {
                        font-size: 1.2rem;
                        font-weight: 400;
                        color: $lightOrange;
                    }
                }
            }
        }
    }

}