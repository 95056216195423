@import "variables";

._errorPage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    h1 {
        font-size: 30rem;
        font-weight: 600;
        opacity: 0.2;
        margin: 0;
        padding: 0;
    }

    h2 {
        font-size: 5rem;
        line-height: 5.1rem;
        opacity: 0.5;
        text-transform: uppercase;
    }

    a {
        color: $lightOrange;
        font-weight: 600;
        font-size: 1.6rem;
    }
}