@import "variables";

#_events_calendar {
    margin-top: 30px;
    display: block;
    border-bottom: 5px solid $eventCalendarHeadBorderColor;

    ._head {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        &:after {
            content: '';
            width: 1px;
            height: 50px;
            background: $eventCalendarHeadBorderColor;

            position: absolute;
            left: 50%;
            top: 0;
        }

        a._full_month {
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 50px;
            background: $eventCalendarHeadFullMonthBg;
            color: $eventCalendarHeadFullMonthColor;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 1.2rem;
            line-height: 1.4rem;
            font-weight: 300;
            text-decoration: none;
        }

        ._left,
        ._right {
            flex-basis: 50%;
            background: $eventCalendarHeadBg;
            padding: 0 0 0 15px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            h3, h4 {
                font-weight: 300;
                margin: 0;
                padding: 0;
                color: white;
            }

            h3 {
                font-size: 1.4rem;
                line-height: 1.8rem;
            }

            h4 {
                font-size: 1.1rem;
                line-height: 1.5rem;
            }
        }
    }

    ._details {
        background: $eventCalendarDetailsBg;
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        &:after {
            content: '';
            width: 1px;
            height: 100%;
            background: $eventCalendarHeadBorderColor;

            position: absolute;
            left: 50%;
            top: 0;
        }

        ._left,
        ._right {
            flex-basis: 50%;
            padding: 15px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;

            ._all_this_day {
                color: white;
                font-size: 1.1rem;
                line-height: 1.4rem;
                text-decoration: none;
                margin-top: 15px;
            }

            ._event {
                margin: 2px 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-decoration: none;

                img {
                    margin-right: 10px;
                    height: 40px;
                    width: 40px;
                }

                span {
                    font-size: 1.1rem;
                    line-height: 1.4rem;
                    color: $eventCalendarDetailsCategoryColor;
                    font-weight: 300;
                    margin: 5px 0;

                    strong {
                        display: block;
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                        color: $eventCalendarDetailsTitleColor;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}