@import "variables";

#_main_page {
    margin-top: 30px;

    #_advert_medium {
        display: block;
        margin-top: 30px;

        a {
            display: block;
        }
    }

    @import "main_slider";
    @import "promo_news";
    @import "box";
    @import "box_cameras";
    @import "box_badge";
    @import "box_banners";
    @import "box_banners_small";
    @import "box_newsletter";
    @import "box_questionnaire";
    @import "box_events_calendar";
    @import "box_close_events";

}