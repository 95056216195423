@import "variables";

#_calendar_head_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: $calendarBg;
    color: white;

    ._navigation {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            display: inline-block;
            padding: 15px;
            color: $calendarNaviColor;
            font-size: 1.2rem;
            line-height: 1.4rem;
            text-decoration: none;
            @include animation;

            &:hover { background: $calendarWeekDayBg;}
        }

        span._name_month {
            display: inline-block;
            padding: 15px;
            border-left: 1px solid $calendarNaviBorder;
            border-right: 1px solid $calendarNaviBorder;
            font-size: 1.2rem;
            line-height: 1.4rem;
        }
    }

    ._category_list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 10px;

        a {
            display: inline-block;
            color: white;
            font-size: 1.2rem;
            line-height: 1.6rem;
            margin-right: 30px;

            &._music {
                padding-left: 20px;
                background: url('../img/music-icon.png') no-repeat left center;
            }

            &._culture {
                padding-left: 24px;
                background: url('../img/culture-icon.png') no-repeat left center;
            }

            &._sport {
                padding-left: 30px;
                background: url('../img/sport-icon.png') no-repeat left center;
            }

            &._children {
                padding-left: 30px;
                background: url('../img/children-icon.png') no-repeat left center;
            }

            &._active { color: $lightOrange; font-weight: 700;}
        }
    }
}

#_calendar {
    display: table;
    width: 100%;
    background: $calendarBg;
    color: white;

    ._week_days,
    ._week_content {
        display: table-row;

        ._day,
        ._day_content {
            display: table-cell;
            padding: 10px;
        }

        ._day {
            border-right: 1px solid $calendarWeekDayBorder;
            background: $calendarWeekDayBg;

            ._title {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                ._number {
                    font-size: 2.4rem;
                    line-height: 2.8rem;
                    font-weight: 700;
                    color: white;
                    width: 40px;
                }

                ._name {
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.2rem;
                    text-transform: uppercase;
                }
            }

            &._weekend {
                background: $calendarWeekendTitleBg;
                border-right: 1px solid $calendarWeekendTitleBorder;
            }
        }

        ._day_content {
            border-right: 1px solid $calendarWeekContentBorder;
            border-bottom: 1px solid $calendarBg;
            padding: 10px 10px 50px 10px;
            position: relative;

            &._weekend {
                border-bottom: 1px solid $calendarWeekendContentBg;
                background: $calendarWeekendContentBg;
                border-right: 1px solid $calendarWeekendContentBorder;
            }
        }
    }

    ._event {
        display: block;
        margin-bottom: 10px;
        color: white;

        img {
            display: block;
            margin-bottom: 5px;
        }

        ._name {
            font-size: 1.1rem;
            line-height: 1.4rem;
            display: block;
            color: $calendarEventNameColor;
        }
        ._category {
            font-size: .9rem;
            line-height: 1.2rem;
            display: block;
            color: $calendarEventCategoryColor;
        }
    }

    ._more {
        position: absolute;
        bottom: 10px;
        left: 10px;
        text-transform: uppercase;
        color: $lightOrange;
        font-size: 0.9rem;
        line-height: 1.2rem;
    }
}

#_calendar_day {

    ._event {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 15px;

        &:nth-child(odd) {
            background: $newsListBackgroundEvenColor;
        }

        figure {
            margin: 0;
            padding: 0;
        }

        ._details {
            flex-basis: calc( 100% - 200px );

            ._title {
                color: #4d4d4d;
                font-weight: 600;
                font-size: 1.8rem;
                line-height: 2.2rem;
                text-decoration: none;
                margin-bottom: 15px;
                display: block;
            }

            ._category {
                margin-top: 15px;
                display: block;
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: $lightOrange;
                font-weight: 300;
                font-style: italic;
            }

            ul {
                list-style: none;
                display: flex;
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: wrap;
                margin: 0;
                padding: 0;

                li {
                    flex-basis: 50%;
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                    padding: 2px 10px;
                }
            }
        }
    }
}