@import "variables";

#_recommend {
    display: block;
    margin-top: 50px;
    padding: 10px 0;
    border-top: 1px solid $recommendTopBorderColor;

    div {

        a {
            display: block;
            text-align: center;
            img {
                height: 120px;
            }
        }
    }

    .prev,
    .next {
        position: absolute;
        left: -30px;
        width: 26px;
        height: 36px;
        line-height: 4rem;
        text-align: center;
        font-size: 2rem;
        top: 50%;
        margin-top: -18px;
        cursor: pointer;
        color: $recommendArrowsColor;
        z-index: 1;
        opacity: .5;
        @include animation;

        &:hover { opacity: 1;}

        i {
            position: relative;
        }
    }
    .next {
        left: auto;
        right: -30px;
    }
}